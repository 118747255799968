<template>
  <t-page>
    <template #header>
      <h3>{{ $t('fields.employees') }}</h3>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table
          ref="table"
          :query="query"
          :fields="fields"
          :filters="filters"
          @delete="onDeleteConfirmation"
        />
      </t-grid-cell>
    </t-grid>

    <portal to="modals">
      <t-confirm
        v-if="deleteConfirmationVisible"
        :button-label="$t('global.actions.confirm_deletion')"
        @confirmed="destroy"
        @cancelled="closeModal"
      >
        <template #header>
          {{ $t('global.confirmation.delete') }}
        </template>
        <p>{{ $t('global.confirmation.final_action') }}</p>
      </t-confirm>
    </portal>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Employee from '~/models/Employee';
import IndexTable from '~/pages/shared/employees/partials/IndexTable';

export default Vue.extend({
  components: { IndexTable },

  middleware: 'admin',

  data: () => ({
    filters: [],

    fields: ['fullname', 'email', 'meta.phonenumber', 'meta.position', 'created_at', 'actions'],

    deleteConfirmationVisible: false,
    deletingEmployeeId: null,
  }),

  methods: {
    query() {
      return new Employee().where('organization_id', this.$route.params.id).include('user');
    },

    onDeleteConfirmation(id) {
      this.deletingEmployeeId = id;
      this.deleteConfirmationVisible = true;
    },

    closeModal() {
      this.deletingEmployeeId = null;
      this.deleteConfirmationVisible = false;
    },

    async destroy() {
      try {
        await new Employee({
          id: this.deletingEmployeeId,
        }).delete();
        await this.$notify.success(this.$t('notifications.employee.deleted'));
        this.$refs.table.resources = this.$refs.table.resources.filter(employee => employee.id !== this.deletingEmployeeId);
        this.closeModal();
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>
